import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ELEMENT from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios';
import DifferentEnvironment from '@/DifferentEnvironment'
import {
	postRefresh
} from '@/api/api.js'
Vue.prototype.axios = axios
Vue.config.productionTip = false
Vue.use(ELEMENT)

var autoRedirect = function() {
	// 监听屏幕宽度决定进入移动端或pc端
	var w = window.screen.width;
	if (parseInt(w) < 960) {
		window.location.href = DifferentEnvironment.response
	}
};
autoRedirect();
window.onresize = function() {
	autoRedirect();
};
router.beforeEach((to, from, next) => {
	if (localStorage.getItem('refresh_token') != '' && localStorage.getItem('refresh_token') !=
		undefined && !localStorage.getItem('access_token') && to.name != 'SignIn') {
		postRefresh({

		}).then((res) => {
			if (res.data.code == 200) {
				localStorage.setItem('access_token', res.data.access_token)
				next()
			} else {
				router.push({
					name: 'SignIn',
				})
			}
		}).catch(() => {
			router.push({
				name: 'SignIn',
			})
		})
	} else if (to.name == 'PrivacyTerms' || to.name == 'RegisterService' || to.name == 'HomeView' || to.name ==
		'FormOfBid' || to.name == 'EnterpriseServices' || to.name == 'AboutUs' || to.name ==
		'DisclaimerStatement' || to.name == 'PageLost') {
		next()
	} else if (to.name != 'SignIn' && !localStorage.getItem('access_token')) {
		if (location.pathname != '/signin') {
			router.push({
				name: 'SignIn'
			})
		}
	} else {
		next()
	}
})
new Vue({
	router,
	data() {
		return {
			global: DifferentEnvironment.globals
		}
	},
	render: h => h(App)
}).$mount('#app')
