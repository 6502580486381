<script>
	var local
	var target
	var globals
	var response
	var state = 1
	// state == 1时为标标通
	// state == 2时为能者科技
	if (state == 1) {
		if (process.env.NODE_ENV === "development") {
			// 网址
			local = 'http://wechat.ddcloud.bigbe.cn'
			// local = 'http://192.168.0.40:8000'
			// target = 'http://api-test.ddcloud.bigbe.cn'
			// 接口地址
			target = 'http://ddcloud-dev.tpddns.cn:32782'
			// target = 'http://ddcloud-dev.tpddns.cn:32782'

		} else if (process.env.NODE_ENV === 'test') {
			local = 'http://ddcloud-dev.tpddns.cn:32799'
			target = 'http://ddcloud-dev.tpddsssns.cn:32782'
		} else if (process.env.NODE_ENV === 'production') {
			local = 'https://biaobiaotong.cn'
			target = 'https://api.biaobiaotong.cn'
		}
		// 主题色
		globals = {
			themeColor: '#66CCAA',
		}
		// 图标
		response = 'https://wechat.biaobiaotong.cn'
	} else if (state == 2) {
		if (process.env.NODE_ENV === "development") {
			local = 'http://wechat-test.ddcloud.bigbe.cn'
			// local = 'http://192.168.0.40:8000'
			// target = 'http://api-test.ddcloud.bigbe.cn'
			target = 'http://ddcloud-dev.tpddns.cn:32782'
			// target = 'http://ddcloud-dev.tpddns.cn:32782'

		} else if (process.env.NODE_ENV === 'test') {
			local = 'http://ddcloud-dev.tpddns.cn:32799'
			target = 'http://ddcloud-dev.tpddsssns.cn:32782'
		} else if (process.env.NODE_ENV === 'production') {
			local = 'http://wechat.ddcloud.bigbe.cn'
			target = 'http://api.ddcloud.bigbe.cn'
		}
		// 主题色
		globals = {
			themeColor: '#1989fa',
		}
		// 图标
		response = 'http://wechat.ddcloud.bigbe.cn'
	}

	export default {
		local,
		target,
		globals,
		state,
		response
	}
</script>
